import { FilterType } from "../../model";
import { log } from "../../utils";
import { getAttachment, removeAttachment, setAttachment } from "../attachment";
import { PageBase } from "../capture/base";
import { filterAttributes, filterNode, filterText } from "../capture/filter";
import { CanvasImageBitmap } from "../capture/message-events.types";
import {
  IAttributes,
  ICanvasImage,
  INodeV3,
  IStyle,
  PageUpdateV3,
} from "../models";
import { Walker } from "../walker";

export type INodeTree = number | INodeTree[];

export interface IFragment {
  parent: number;
  previous: number;
  next: number;
  /** first level are siblings, in the rest, the first one is the parent, what follows are its children */
  tree: INodeTree[];
  nodes: Record<number, INodeV3>;
}

export interface Mutations {
  nodes?: {
    insert: IFragment[];
    remove: number[];
  };
  attributes?: Record<number, IAttributes>;
  values?: Record<number, string>;
  metrics?: {
    captureTime: number;
  };
}

export interface IObserverDirtyNodes {
  inserted: boolean;
  attrs: { [key: string]: boolean };
  value: boolean;
}

interface PostProcessSheet {
  id: number;
  target: number;
  sheet?: {
    // for style/link elements, constructed ones are standalone stylesheets events
    href?: string;
    rules: string;
  };
}

export class Observer {
  public isTracking = false;

  private observer: MutationObserver;
  private tracking: Array<Element | ShadowRoot> = [];

  private created = new Set<Node>();
  private removed = <number[]>[];
  private attributes = new Set<HTMLElement>();
  private texts = new Set<Node>();

  private intersectionObserver: IntersectionObserver = null;

  /** every now and then we compare current content with old one and send it if it is different */
  private postProcessCanvas = new Map<
    HTMLCanvasElement,
    {
      data: Uint8Array | null;
      pending: boolean;
      ts: number;
      isVisible: boolean;
      format: ICanvasImage["payload"]["format"];
    }
  >();

  private postProcessFrames: HTMLIFrameElement[] = [];
  private postProcessInputs: Element[] = [];
  private postProcessScroll: Element[] = [];
  private postProcessStyles: PostProcessSheet[] = [];
  private postProcessStylePointers: IStyle["payload"][] = [];
  /** some styles are applied with delay, check N times max if any are added to shadow roots with none */
  private postLookupStyles = new Map<ShadowRoot, number>();

  private sheetCounter = 1;
  private sheetIds = new WeakMap<CSSStyleSheet, number>();

  constructor(private page: PageBase) {}

  /**
   * Registers observer on element and process each record list
   */
  public track(target: Element | ShadowRoot) {
    this.observer ??= new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        switch (mutation.type) {
          case "attributes":
            this.processAttributes(mutation);
            break;
          case "characterData":
            this.processCharacterData(mutation);
            break;
          case "childList":
            this.processChildList(mutation);
            break;
        }
      }
    });

    this.intersectionObserver ??= new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const cnvInfo = this.postProcessCanvas.get(
          entry.target as HTMLCanvasElement
        );

        if (!cnvInfo) {
          this.intersectionObserver?.unobserve(entry.target);
        } else {
          cnvInfo.isVisible = entry.isIntersecting;
        }
      });
    });

    if (!this.tracking.includes(target)) {
      this.tracking.push(target);
      this.observe(target);
      this.isTracking = true;
    }
  }

  private observe(target: Element | ShadowRoot) {
    this.created.add(target);
    this.observer.observe(target, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
      attributeOldValue: false,
      characterDataOldValue: false,
    });
  }

  public untrack() {
    this.observer?.disconnect();
    this.intersectionObserver?.disconnect();
    this.isTracking = false;
    this.tracking = [];
    this.postProcessCanvas.clear();
    this.postLookupStyles.clear();
  }

  private processAttributes(mutation: MutationRecord) {
    const props = getAttachment(mutation.target);

    if (props && props.filter !== FilterType.NoDisplay) {
      props.attributes[mutation.attributeName] = this.page.serializer.attribute(
        mutation.target as Element,
        mutation.attributeName
      );

      if (props.id) {
        this.attributes.add(mutation.target as HTMLElement);
      }
    }
  }

  private processCharacterData(mutation: MutationRecord) {
    const props = getAttachment(mutation.target);

    if (props) {
      props.text = this.page.serializer.text(mutation.target);

      if (props.id) {
        this.texts.add(mutation.target);
      }
    }
  }

  private processChildList(mutation: MutationRecord) {
    const props = getAttachment(mutation.target);

    // non-attached nodes will either be scanned by a parent or ignored by filter
    // also skip filtered direct parent
    if (
      !props ||
      props.filter === FilterType.Display ||
      props.filter === FilterType.NoDisplay
    ) {
      return;
    }

    mutation.removedNodes.forEach((node) => {
      const props = getAttachment(node);

      // non-attached nodes have been inserted and removed in same cycle
      if (props) {
        new Walker(node).sprint((child) => {
          this.page.idp.removeId(getAttachment(child)?.id);
          removeAttachment(child);
        });

        if (props.id) {
          this.removed.push(props.id);
        }
      }
    });

    mutation.addedNodes.forEach((node) => this.created.add(node));
  }

  /** is element visible in viewport */
  private inViewport(elm: HTMLCanvasElement) {
    return this.postProcessCanvas.get(elm).isVisible;
  }

  public postProcess() {
    const updates: PageUpdateV3[] = [];

    for (const [cnv, info] of this.postProcessCanvas) {
      if (!cnv.isConnected) {
        this.postProcessCanvas.delete(cnv);
      } else if (Date.now() - info.ts > 1200 && this.inViewport(cnv)) {
        info.ts = Date.now();

        this.page
          .getWindow()
          .createImageBitmap?.(cnv)
          .then((bitmap) => {
            this.page.messenger.sendPageUpdate(
              {
                type: "CanvasImageBitmap",
                bitmap,
                height: cnv.height,
                width: cnv.width,
                id: this.page.idp.getId(cnv),
                document: this.page.documentId,
              } as CanvasImageBitmap,
              [bitmap]
            );
          })
          .catch((ex) => log(ex));
      }
    }

    for (const frame of this.postProcessFrames) {
      this.page.trackFrame(frame);
    }

    this.postProcessFrames = [];

    for (let [shadowRoot, tries] of this.postLookupStyles) {
      if (tries--) {
        this.trackAdoptedSheets(shadowRoot);
        this.postLookupStyles.set(shadowRoot, tries);
      } else {
        this.postLookupStyles.delete(shadowRoot);
      }
    }

    for (const style of this.postProcessStyles) {
      if (style.sheet.rules)
        updates.push(
          this.page.event<IStyle>("StyleSheet", {
            id: style.id,
            target: style.target,
            format: "text",
            href: style.sheet.href,
            rules: style.sheet.rules,
          })
        );
    }

    this.postProcessStyles = [];

    for (const style of this.postProcessStylePointers) {
      updates.push(this.page.event<IStyle>("StyleSheet", style));
    }

    this.postProcessStylePointers = [];

    for (const input of this.postProcessInputs) {
      this.page.input.capture({ target: input });

      switch (input.tagName) {
        case "INPUT":
          this.page.input.hookInstance(input, "checked");
        // fallthrough
        case "TEXTAREA":
        case "SELECT":
          this.page.input.hookInstance(input, "value");
          break;
        case "OPTION":
          this.page.input.hookInstance(input, "selected");
          break;
      }
    }

    this.postProcessInputs = [];

    for (const scroll of this.postProcessScroll) {
      this.page.scroll.capture({ target: scroll });
    }

    this.postProcessScroll = [];

    return { events: updates };
  }

  private trackAdoptedSheets(
    node: ShadowRoot,
    target = this.page.idp.getId(node)
  ) {
    for (const sheet of node.adoptedStyleSheets) {
      if (this.sheetIds.has(sheet)) {
        this.postProcessStylePointers.push({
          id: this.sheetIds.get(sheet),
          target,
          format: "pointer",
          rules: "",
        });
      } else {
        this.sheetIds.set(sheet, this.sheetCounter);
        this.postProcessStyles.push({
          id: this.sheetCounter++,
          target,
          sheet: { rules: this.page.serializer.stylesheet(sheet) },
        });
      }
    }
  }

  private visit(node: Node, filter: FilterType) {
    const props = setAttachment(
      node,
      node.nodeType === node.ELEMENT_NODE
        ? this.page.serializer.attributes(node as Element)
        : undefined,
      node.nodeType === node.TEXT_NODE
        ? this.page.serializer.text(node)
        : undefined,
      filter
    );

    const info = this.page.serializer.node(node, props);

    // deal with shadow roots which have their own separate dom tree
    if (
      (node as HTMLElement).shadowRoot?.nodeType ===
        node.DOCUMENT_FRAGMENT_NODE &&
      (filter === FilterType.None || filter === FilterType.DisplayRandomized)
    ) {
      this.observe((node as HTMLElement).shadowRoot);
    } else if (node.nodeType === node.DOCUMENT_FRAGMENT_NODE) {
      this.page.cursor.track(node as ShadowRoot);

      if (filter === FilterType.None) this.page.input.track(node as ShadowRoot);

      if ((node as ShadowRoot).adoptedStyleSheets?.length) {
        this.trackAdoptedSheets(node as ShadowRoot, info.id);
      } else {
        this.postLookupStyles.set(node as ShadowRoot, 3);
      }
    }

    // filter and postprocess
    switch (props.filter) {
      case FilterType.None:
        if (node.nodeType === node.ELEMENT_NODE) {
          switch (info.name) {
            case "CANVAS":
              this.postProcessCanvas.set(node as HTMLCanvasElement, {
                ts: 0,
                data: null,
                pending: false,
                isVisible: false,
                format: "image/webp",
              });
              this.intersectionObserver.observe(node as HTMLCanvasElement);
              break;
            case "IFRAME":
              this.postProcessFrames.push(node as HTMLIFrameElement);
              break;
            case "INPUT":
            case "OPTION":
            case "TEXTAREA":
            case "SELECT":
              this.postProcessInputs.push(node as Element);
              break;
            case "STYLE":
              if (info.sheet) {
                this.postProcessStyles.push({
                  id: this.sheetCounter++,
                  target: info.id,
                  sheet: info.sheet,
                });
                delete info.sheet;
              }

              break;
            case "NOSCRIPT":
              this.page.filter.filterNode(
                node,
                (props.filter = FilterType.NoDisplay)
              );
              break;
            default:
              if (this.page.scroll.isScrollable(node as Element)) {
                this.postProcessScroll.push(node as Element);
              }
          }
        }

        break;
      case FilterType.Display: {
        info.filter = props.filter;

        // Get computed styles
        const computedStyle = this.page
          .getWindow()
          .getComputedStyle(node as HTMLElement);

        // Width and height including padding and border, but excluding margin
        const width = (node as HTMLElement).offsetWidth;
        const height = (node as HTMLElement).offsetHeight;

        // Get margins
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        const marginLeft = parseFloat(computedStyle.marginLeft);
        const marginRight = parseFloat(computedStyle.marginRight);

        // make remote of similar initial size, later changes are not synced
        info.attrs.style = `\
border:none;\
width:${width}px;\
height:${height}px;\
display:${info.name === "IFRAME" ? "inline-block" : computedStyle.display};\
margin:${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px;\
${info.attrs.style || ""}\
`;

        if (info.attrs)
          info.attrs = filterAttributes(info.attrs, node as HTMLElement);

        break;
      }
      case FilterType.DisplayRandomized:
        info.filter = props.filter;

        filterNode(info, node as HTMLElement);

        if (node.nodeType === node.ELEMENT_NODE) {
          switch (info.name) {
            case "STYLE":
              this.postProcessStyles.push({
                id: this.sheetCounter++,
                target: info.id,
                sheet: info.sheet,
              });
              break;
            case "LINK":
              info.attrs.href = props.attributes.href;
              break;
            case "SVG":
              info.filter = FilterType.None;
              break;
            case "NOSCRIPT":
              this.page.filter.filterNode(
                node,
                (props.filter = FilterType.NoDisplay)
              );
              break;
            default:
              if (this.page.scroll.isScrollable(node as Element)) {
                this.postProcessScroll.push(node as Element);
              }
          }
        }

        break;
      case FilterType.NoDisplay:
        info.filter = props.filter;
        info.name = "#comment";
        break;
    }

    // reset props for next cycle
    props.attributes = props.attributes ? {} : undefined;
    props.text = undefined;

    return info;
  }

  private deepScan(
    walker: TreeWalker,
    nodes: IFragment["nodes"],
    options = { filter: FilterType.None }
  ) {
    const current = this.visit(walker.currentNode, options.filter);
    nodes[current.id] = current;

    if (
      (options.filter === FilterType.None ||
        options.filter === FilterType.DisplayRandomized) &&
      walker.firstChild()
    ) {
      const tree: INodeTree[] = [
        current.id,
        this.deepScan(walker, nodes, {
          filter:
            options.filter || this.page.filter.getFilter(walker.currentNode),
        }),
      ];

      while (walker.nextSibling())
        tree.push(
          this.deepScan(walker, nodes, {
            filter:
              options.filter || this.page.filter.getFilter(walker.currentNode),
          })
        );

      walker.parentNode();

      return tree;
    } else {
      return current.id;
    }
  }

  /** id and calculate next fragment in target's children */
  private identify(walker: TreeWalker, parent: Node): IFragment {
    let next: Node;
    const tree: INodeTree = [];
    const nodes: IFragment["nodes"] = {};
    const previous = walker.currentNode.previousSibling;

    // scan new fragment of siblings
    do {
      next = walker.currentNode.nextSibling;
      tree.push(
        this.deepScan(walker, nodes, {
          filter:
            this.page.filter.getFilter(parent) ||
            this.page.filter.getFilter(walker.currentNode),
        })
      );
    } while (walker.nextSibling() && !this.page.idp.getId(walker.currentNode));

    return {
      parent: this.page.idp.getId(parent),
      next: this.page.idp.getId(next),
      previous: this.page.idp.getId(previous),
      tree,
      nodes,
    };
  }

  public async flush(): Promise<Mutations> {
    const insert: IFragment[] = [];
    const remove = this.removed;

    // assign ids to nodes and collect their info
    for (const node of this.created) {
      let parent = node.parentNode;
      let walker: TreeWalker;

      if (
        // removed already
        !node.isConnected ||
        // scanned already
        getAttachment(node)?.id ||
        // parent has not been scanned, this will be part of another fragment
        (parent !== this.page.getDocument() &&
          ((parent && !getAttachment(parent)?.id) ||
            // previous has not been scanned, this will be part of another fragment
            (node.previousSibling && !getAttachment(node.previousSibling)?.id)))
      ) {
        continue;
      }

      this.page.filter.scan(node);

      if (parent === this.page.getDocument()) {
        walker = document.createTreeWalker(
          (parent as Document).documentElement
        );
      } else if (
        (node as HTMLElement).nodeType === document.DOCUMENT_FRAGMENT_NODE
      ) {
        parent = (node as ShadowRoot).host;
        walker = document.createTreeWalker(node);
      } else {
        walker = document.createTreeWalker(parent);
        walker.firstChild();

        while (walker.currentNode !== node && walker.nextSibling());
      }

      insert.push(this.identify(walker, parent));
    }

    // collect attributes changes of old nodes
    const attributes: Record<number, IAttributes> = this.attributes.size
      ? {}
      : undefined;

    for (const node of this.attributes) {
      const att = getAttachment(node);

      if (att?.id) {
        attributes[att.id] =
          att.filter === FilterType.DisplayRandomized ||
          att.filter === FilterType.Display
            ? filterAttributes(att.attributes, node)
            : att.attributes;
      }
    }

    // collect text changes of old nodes
    const values: Record<number, string> = this.texts.size ? {} : undefined;

    for (const node of this.texts) {
      const att = getAttachment(node);

      if (att?.id) {
        values[att.id] =
          att.filter === FilterType.DisplayRandomized
            ? filterText(att.text)
            : att.text;
      }
    }

    const nodes =
      insert.length || remove.length ? { insert, remove } : undefined;

    this.created = new Set();
    this.removed = [];
    this.texts = new Set();
    this.attributes = new Set();

    return nodes || attributes || values
      ? {
          nodes,
          attributes,
          values,
        }
      : null;
  }
}
